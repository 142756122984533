// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
    /*production: false,
    apiCentralPortal: 'http://localhost:8081/apirest-central-proterm',
    apiProterm: 'http://localhost:8080/apirest-recursos-humanos-proterm/',
    apiFinanzas: 'http://localhost:8082/apirest-finanzas/',
    apiBitacora: 'http://localhost:8083/apirest-bitacora/',
    apiGestion: 'http://localhost:8084/apirest-gestion/',
    apiCanalDenuncias: 'http://localhost:8085/apirest-canaldenuncias-proterm/',*/
    
    //NO SSL
    /*production: true,
    apiCentralPortal: 'http://intranet.proterm.cl:8080/apirest-central-proterm',
    apiProterm: 'http://intranet.proterm.cl:8080/apirest-recursos-humanos-proterm/',
    apiFinanzas: 'http://intranet.proterm.cl:8080/apirest-finanzas/',
    apiBitacora: 'http://intranet.proterm.cl:8080/apirest-bitacora/',
    apiGestion: 'http://intranet.proterm.cl:8080/apirest-gestion/',*/
    
    /* SSL*/
    production: true, 
    apiCentralPortal: 'https://api.backend.proterm.cl/apirest-central-proterm',
    apiProterm: 'https://api.backend.proterm.cl/apirest-recursos-humanos-proterm/',
    apiFinanzas: 'https://api.backend.proterm.cl/apirest-finanzas/',
    apiBitacora: 'https://api.backend.proterm.cl/apirest-bitacora/',
    apiGestion: 'https://api.backend.proterm.cl/apirest-gestion/',
    apiCanalDenuncias: 'https://api.backend.proterm.cl/apirest-canaldenuncias-proterm/',

    isAdmin: false,
    isSupport: false,
    isUser: false,


};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
